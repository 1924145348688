@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.shares {
  width: 100%;
  .slider {
    width: 70%;
    margin: 0 auto 60px;

    .sliderPicture {
      div {
        display: flex;
        flex-direction: column;

        width: 95%;
        height: 400px;
        margin: 0 auto;
        padding: 40px 0;

        background-position: center;
        background-size: cover;

        span {
          @include text($textFont, 800, 42px, 120%, $textLinkColor);

          @include adpt($bmob-r) {
            @include text($textFont, 800, 22px, 120%, $textLinkColor);

            margin: 60px 10px 20px 10px;
          }

          margin: 80px 70px 20px 70px;
        }

        p {
          @include text($textFont, 500, 20px, 120%, $textLinkColor);

          @include adpt($bmob-r) {
            margin: 0 10px;
          }

          margin: 0 70px;
        }
      }
    }
  }
}

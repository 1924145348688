@import 'src/styles/mixin.scss';
@import 'src/styles/variables.scss';

.room {
  width: 46%;
  margin: 0 5px 20px;
  height: 100%;

  @include adpt($stab-r) {
    width: 90%;
  }
  .roomContainer {
    width: 100%;
    height: 100%;
    min-height: 610px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .roomImg {
      width: 100%;
      height: 60%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .roomTitle {
      @include text($textFont, 700, 26.4px, 29.04px, $textColor);
      padding: 0 20px;
      margin: 10px 0 15px;
    }
    .roomText {
      @include text($textFont, 400, 14.4px, 21.6px, rgba(0, 0, 0, 0.87));
      padding: 20px;
      min-height: 80px;
      -webkit-tap-highlight-color: transparent;
    }

    .buttons {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      &_detailed {
        @include text($textFont, 500, 14.4px, 21.6px, $textColor);
        width: 200px;
        height: 55px;
        border: none;
        margin-bottom: 40px;
        background: $backgroundSecondColorButton;
        cursor: pointer;
        &:hover {
          background: $textLinkColorHover;
        }
      }

      .buttons_reserve {
        width: 200px;
        height: 55px;
        margin-bottom: 40px;

        button {
          width: 100%;
          height: 100%;
          @include text($textFont, 500, 14.4px, 21.6px, $textSecondColor);
          border: none;
          background: $backgroundColorButton;
          cursor: pointer;
          &:hover {
            background: $backgroundColorButtonHover;
          }
        }
      }
    }
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.contacts {
  width: 100%;
  margin: 0 auto 80px;
  .text {
    @include text($textFont, 400, 14px, 22px, rgba(0, 0, 0, 0.87));

    text-align: center;
    a {
      color: #039be5;
      text-decoration: none;
    }
  }
}

$textColor: rgba(0, 0, 0, 0.87);
$textSecondColor: #ffffff;
$textLinkColor: #ffffff;
$textLinkColorHover: #ffaf20;
$textButtonColor: #ffffff;
$backgroundColor: #141414;
$backgroundColorButton: #375b6b;
$backgroundColorButtonHover: #233d4b;
$backgroundSecondColorButton: #f5f5f5;

$big-r: 1380;
$desm-r: 1280;
$tab-r: 1024;
$mtab-r: 992;
$stab-r: 768;
$bmob-r: 576;

$textFont: 'Museo Sans Cyrl', sans-serif;

$borderButton: 0.5px solid;

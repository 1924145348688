@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.container {
  @include adpt($stab-r) {
  }

  display: flex;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
  &__map {
    position: relative;

    width: 100%;
    height: 500px;
    &__buttons {
      position: absolute;
      right: 0;
      bottom: 0;

      display: flex;
      &_bytton {
        @include adpt($stab-r) {
          padding: 10px;
        }

        @include adpt($bmob-r) {
          padding: 5px;
        }

        cursor: pointer;

        width: 60px;
        height: 60px;
        padding: 30px;

        background-color: $backgroundColorButton;
        &:hover {
          background-color: $backgroundColorButtonHover;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  &__contacts {
    @include adpt($stab-r) {
      height: auto;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    height: 220px;
    padding: 20px;
  }
  &__contact {
    @include adpt($stab-r) {
      margin: 0 auto 10px;
      width: 60%;
      max-width: 250px;
    }
    @include adpt($bmob-r) {
      width: 100%;
    }

    display: flex;

    width: 40%;
    height: 40px;
    margin: 15px 5px;
    a {
      display: flex;
      align-items: center;

      text-decoration: none;

      img {
        margin-right: 10px;
      }
      span {
        @include text($textFont, 300, 18px, 24px, #263d4b);
        width: 100%;
        @include adpt($stab-r) {
          max-width: 215px;
        }
      }
    }
  }
  &__shimko {
    width: 300px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}

@import '../../../../styles/mixin';

@import '../../../../styles/variables';

.homeHeader {
  display: flex;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  background-position: center;
  background-size: cover;

  .slider {
    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    .lining {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      min-height: 100%;
      max-height: 100%;

      opacity: 0.8;
      background-color: #000000;
    }
    .textBlock {
      @include adpt($tab-r) {
        width: 90%;
        margin: 0 auto;
      }
      @include adpt(1160) {
        margin: 50px auto 10px;
      }
      @include adpt($stab-r) {
        margin: 0 auto;
      }
      position: relative;
      z-index: 2;

      display: flex;
      flex-direction: column;
      align-items: start;

      width: 70%;
      margin: 140px auto;

      h1 {
        @include text($textFont, 700, 42px, 60px, $textSecondColor);

        @include adpt($bmob-r) {
          @include text($textFont, 700, 36px, 48px, $textSecondColor);

          margin-bottom: 0;
        }
      }

      p {
        @include text($textFont, 300, 24px, 32px, $textSecondColor);
      }
      form {
        @include adpt($bmob-r) {
          width: 288px;
          margin: 0 auto;
        }

        display: block;

        width: 310px;
        height: 85px;
        button {
          @include text($textFont, 500, 16.8px, 22px, $textSecondColor);

          cursor: pointer;

          width: 100%;
          height: 100%;

          background-color: $backgroundColorButton;
          border: none;

          &:hover {
            background: $backgroundColorButtonHover;
          }
        }
      }
    }

    .videoBlock {
      @include adpt($tab-r) {
        position: relative;
        bottom: 0;

        width: 90%;
        margin: 20px auto;
      }

      position: absolute;
      right: 0;
      bottom: 10%;

      display: flex;

      width: 50%;
      height: 50%;
      margin: 0 auto;
      iframe {
        @include adpt($tab-r) {
          border: 5px solid #ffaf20;
          max-width: 520px;
          margin: 0 auto;
        }

        border: 20px solid #ffaf20;
      }
    }

    .buttons {
      @include adpt($stab-r) {
        display: none;
      }

      position: absolute;
      z-index: 2;
      top: 23%;
      right: 3%;

      button {
        cursor: pointer;

        width: 122px;
        height: 122px;

        background-color: transparent;
        border: none;

        img {
          width: 32px;
          height: 50px;
        }
      }
    }
  }
}

@import '../../../../../styles/mixin';
@import '../../../../../styles/variables';

.reviewContainer {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include adpt($stab-r) {
    flex-direction: column;
  }
  img {
    width: 45%;
    min-height: 200px;
    @include adpt($stab-r) {
      width: 100%;
      height: 45%;
    }
  }
  .review {
    width: 55%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include adpt($stab-r) {
      width: 100%;
      height: 55%;
    }
    .reviewTitle {
      @include text($textFont, 700, 24px, 28px, $textColor);
      padding: 0 20px;
      margin: 10px 0 15px;
    }
    .reviewText {
      @include text($textFont, 400, 16px, 24px, rgba(0, 0, 0, 0.87));
      padding: 0 20px;
      -webkit-tap-highlight-color: transparent;
    }
  }
}

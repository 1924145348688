@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.ourHostel {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background-color: #f5f5f5;
  .ourHostelContainer {

    @include adpt($tab-r) {
      flex-direction: column;

      width: 90%;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 70%;
  }
}

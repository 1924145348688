@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.aboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
}

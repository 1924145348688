@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.servicesBlock {
  position: relative;

  width: 100%;
  .imgBlock_left {
    position: absolute;
    z-index: -1;
    top: -100px;

    overflow: hidden;
    display: inline-flex;
    justify-content: center;

    width: 500px;
    max-width: 30%;
    height: 110%;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
}

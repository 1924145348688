@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.newsContainer {
  .newsImg {
    max-width: 800px;
    img {
      width: 100%;
    }
  }
  .context {
    padding: 20px;
  }
}

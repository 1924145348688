@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.homeRooms {
  position: relative;

  width: 100%;
  .imgBlock_left {
    position: absolute;
    z-index: -1;
    top: 100px;

    overflow: hidden;
    display: inline-flex;
    justify-content: center;

    width: 500px;
    max-width: 30%;
    height: 100%;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
  .homeRoomsContainer {
    @include adpt($tab-r) {
      width: 75%;
    }

    width: 70%;
    margin: 0 auto 30px;
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.background {
  background-color: $backgroundColor;

  .footer {
    @include flexColumnJustify(space-between);
    padding-top: 30px;

    @include adpt($tab-r) {
      width: 85%;
    }
    @include adpt($stab-r) {
      align-items: center;
      width: 100%;
    }

    width: 70%;
    margin: 0 auto;

    .footerBlocks {
      @include flexCenterJustify(space-between);
      align-items: flex-start;

      @include adpt($stab-r) {
        @include flexColumnJustify(space-between);
        width: 90%;
        align-items: center;
        font-size: 20px;
        text-align: center;
      }

      .footerBlock1 {
        width: 25%;
        @include adpt($tab-r) {
          width: 20%;
        }
        @include adpt($stab-r) {
          width: 100px;
          margin: 0 auto;
        }
        .logo {
          width: 100%;
        }

        .text {
          @include text($textFont, 400, 14.4px, 21.6px, $textLinkColor);

          @include adpt($stab-r) {
            display: none;
          }

          width: 100%;
        }
      }

      .footerBlock2 {
        @include flexColumnJustify(space-evenly);
        @include adpt($tab-r) {
          padding-left: 24px;
        }
        @include adpt($stab-r) {
          width: 90%;
          padding: 0;
        }

        width: 42%;
        height: 100%;
        padding-left: 44px;

        .menu {
          @include flexColumnJustify(space-evenly);

          @include adpt($stab-r) {
            margin: 20px auto;
            align-items: center;
          }

          flex-wrap: wrap;
          align-items: flex-start;

          width: 100%;
          max-width: 300px;
          max-height: 200px;
          margin: 0 0 50px;
          a {
            @include text($textFont, 400, 14.4px, 3, $textLinkColorHover);

            width: 140px;
            height: 36px;
            text-align: start;
            text-decoration: none;
            //@include adpt($stab-r) {
            //  text-align: center;
            //}
            &:hover {
              color: $textLinkColor;
              text-decoration: none;
            }
          }
        }

        .links {
          a {
            @include text($textFont, 400, 14.4px, 21.6px, $textLinkColor);

            display: block;

            margin-bottom: 10px;

            text-decoration: none;

            &:hover {
              color: $textLinkColorHover;
              text-decoration: none;
            }
          }
        }
      }

      .footerBlock3 {
        @include adpt($stab-r) {
          width: 90%;
        }

        width: 33%;

        button {
          @include text($textFont, 500, 16.8px, 25.2px, $textButtonColor);

          @include adpt($stab-r) {
            width: 250px;
            margin-top: 10px;
          }

          cursor: pointer;

          width: 100%;
          height: 90px;
          margin-top: 80px;

          background-color: $backgroundColorButton;
          border: none;
          &:hover {
            background: $backgroundColorButtonHover;
          }
        }

        div {
          @include adpt($stab-r) {
            margin-top: 10px;
          }

          margin-top: 60px;

          a {
            @include text($textFont, 700, 29px, 33px, $textLinkColorHover);

            text-decoration: none;

            @include adpt($desm-r) {
              font-size: 26px;
            }
            @include adpt($tab-r) {
              font-size: 24px;
            }
            @include adpt($mtab-r) {
              font-size: 23px;
            }
            @include adpt($stab-r) {
              font-size: 22px;
            }

            &:hover {
              cursor: pointer;

              color: $textLinkColor;
              text-decoration: none;
            }
          }

          p {
            @include text($textFont, 400, 20.568px, 19.5px, $textButtonColor);
            margin: 14px 0;
            @include adpt($big-r) {
              font-size: 18px;
            }
            @include adpt($desm-r) {
              font-size: 17px;
            }
            @include adpt($tab-r) {
              font-size: 17px;
            }
          }
        }

        .socials {
          @include adpt($stab-r) {
            justify-content: space-evenly;
          }

          display: flex;
          align-items: center;

          margin-top: 20px;
          a {
            width: 24px;
            height: 24px;
            margin-right: 16px;
            img {
              width: 24px;
            }
          }
        }
      }
    }

    .footerInfo {
      @include text($textFont, 400, 14px, normal, $textLinkColor);

      @include flexCenterJustify(space-between);

      @include adpt($bmob-r) {
        flex-direction: column;
      }

      @include adpt($stab-r) {
        width: 100%;
        margin-top: 20px;
        flex-direction: column;
      }

      margin: 70px 0 30px;
      .footerInfoLinks {
        display: flex;
        width: 100%;
        @include adpt($stab-r) {
          justify-content: space-evenly;
        }

        a {
          @include text($textFont, 300, 12px, normal, $textLinkColor);
          margin-right: 28px;
          text-decoration: none;

          @include adpt($stab-r) {
            margin: 0 0 20px;
            text-align: center;
          }
        }
      }
      .hostel2028 {
        width: 100%;
        text-align: end;
        @include adpt($stab-r) {
          text-align: center;
        }
      }
    }
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.infoBlock {
  width: 100%;
  padding: 10px 0 70px;

  background-color: #f5f5f5;
  &__text {
    @include text($textFont, 400, 14.4px, 22px, rgba(0, 0, 0, 0.87));

    width: 70%;
    margin: 0 auto;
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.newsContainer {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 460px;
  height: 100%;
  min-height: 450px;

  text-align: center;

  .news {
    width: 100%;
    height: 100%;

    .newsImg {
      width: 100%;
      max-width: 460px;
      height: 50%;
      min-height: 250px;

      background-position: center;
      background-size: cover;
    }

    .newsTitle {

      @include text($textFont, 600, 22px, 28px, $textColor);

      overflow: hidden;
      display: -webkit-box;

      height: 50px;
      margin: 10px 0 15px;
      padding: 0 5%;

      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .newsText {

      @include text($textFont, 400, 16px, 24px, $textColor);

      overflow: hidden;
      display: -webkit-box;

      height: 80px;
      padding: 0 5%;

      text-align: start;

      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      -webkit-tap-highlight-color: transparent;
    }

    .newsData {
      position: absolute;
      bottom: 20px;
      left: 5%;
    }
  }
}

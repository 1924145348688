@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.homeFAQ {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  .button {
    @include text($textFont, 500, 14.4px, 21.6px, $textSecondColor);

    cursor: pointer;

    width: 225px;
    height: 55px;
    margin: 40px 0;

    background-color: $backgroundColorButton;
    border: none;
    &:hover {
      background: $backgroundColorButtonHover;
    }
  }
}

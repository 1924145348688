@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.containerVisa {

  @include adpt($tab-r) {
    width: 100%;
  }

  width: 75%;
  margin: 0 auto;
}

.gallery {
  width: 100%;
  .galleryContainer {
    width: 70%;
    margin: 0 auto 40px;
  }
  .slide {
    width: 95%;
    height: 343px;
    margin: 0 auto;

    background-position: center;
    background-size: cover;
  }
}

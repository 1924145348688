@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.roomsBlock {
  position: relative;

  width: 100%;
  .imgBlock_left {
    position: absolute;
    z-index: -1;
    top: -100px;

    overflow: hidden;
    display: inline-flex;
    justify-content: center;

    width: 500px;
    max-width: 30%;
    height: 110%;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
  &_container {
    @include adpt($tab-r) {
      width: 90%;
      justify-content: center;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.aboutUsHeader {
  position: relative;

  width: 100%;

  .imgBlock_left {
    position: absolute;
    z-index: -1;
    top: -5px;

    overflow: hidden;
    display: inline-flex;
    justify-content: center;

    width: 500px;
    max-width: 30%;
    height: 105%;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
  .galleryContainer {
    width: 70%;
    margin: 0 auto 40px;
  }
  .slide {
    width: 100%;
    height: 400px;

    background-position: center;
    background-size: cover;
  }
  .container {
    padding: 30px;
    &__header {
      margin-top: 0;

      text-align: center;
      &_title {
        margin-bottom: 40px;
      }
      &_text {

        @include text($textFont, 400, 14px, 17px, rgba(0, 0, 0, 0.45));

        text-align: end;
      }
    }
    &__body {
      &_title {

        @include text($textFont, 600, 30px, 42px, $textColor);

        margin: 60px 0;

        font-style: italic;
      }
      &_subTitle {

        @include text($textFont, 600, 28px, 36px, $textColor);

        margin-bottom: 20px;
      }
      &_text {

        @include text($textFont, 400, 14px, 22px, rgba(0, 0, 0, 0.45));

        margin-bottom: 40px;
      }
    }
    &__buttons {
      display: flex;
      justify-content: space-between;

      &_questions {

        @include text($textFont, 400, 18px, 22px, black);

        cursor: pointer;

        width: 340px;
        height: 70px;

        background: $backgroundSecondColorButton;
        border: none;
        &:hover {
          background: $textLinkColorHover;
        }
      }

      &_contacts {

        @include text($textFont, 400, 18px, 22px, $textButtonColor);

        cursor: pointer;

        width: 340px;
        height: 70px;

        background: $backgroundColorButton;
        border: none;
        &:hover {
          background: $backgroundColorButtonHover;
        }
      }
    }
  }
}

@import '../../../styles/mixin';

@import '../../../styles/variables';

.header {
  @include flexColumnJustify(space-between);

  @include adpt($stab-r) {
    margin: 0;
    padding: 39px 0 30px 0;
  }

  align-items: center;

  width: 100%;
  margin: 100px 0 46px 0;

  font-family: 'Poppins', sans-serif;
  text-align: center;

  .title {
    @include text($textFont, 700, 42.72px, 40px, $backgroundColor);

    @include flexCenterJustify(space-between);

    @include adpt($tab-r) {
      font-size: 30px;
    }

    @include adpt($bmob-r) {
      font-size: 26px;
    }

    width: 100%;
    .lineBefore,
    .lineAfter {
      height: 10px;
    }
  }

  .text {
    @include text($textFont, 400, 16px, 160%, $backgroundColor);

    max-width: 700px;

    text-align: center;
  }
}

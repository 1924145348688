@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';
.language {
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  min-width: 70px;

  .active {
    color: #ffaf20;
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.newsBlock {
  position: relative;

  width: 100%;
  &_container {

    @include adpt($tab-r) {
      width: 90%;
    }

    display: flex;
    flex-wrap: wrap;

    width: 70%;
    margin: 0 auto;
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.home {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  h2 {

    @include text($textFont, 3.56rem, 400, 33px, $textColor);
  }
}

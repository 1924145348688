@import '../../../../../styles/mixin';

@import '../../../../../styles/variables';

.booking {
  @include flexColumnJustify(center);

  align-items: center;

  margin: 50px 0;
  .bookingLine {
    width: 115px;
    height: 10px;
    margin-bottom: 40px;

    background: #e3a900;
  }
  span {
    @include text($textFont, 800, 20px, 28px, $textSecondColor);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 56px;
    height: 56px;
    margin-top: 30px;

    background: #003580;
    border-radius: 12px 12px 12px 0;
  }
  p {
    @include text($textFont, 500, 16px, 20px, $textColor);
  }
}

@import '../../../../styles/mixin';

@import '../../../../styles/variables';

.reviews {
  width: 100%;

  background-color: #f5f5f5;
  .reviewsContainer {
    width: 70%;
    margin: 0 auto 60px;
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.whyUsCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px;

  .picture {
    align-items: center;

    img {
      width: 80px;
      height: 80px;
    }
  }

  p {
    @include text($textFont, 400, 18px, 24px, $textColor);

    max-width: 150px;

    text-align: center;
  }
}

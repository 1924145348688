@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.newsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  text-align: center;

  .news {
    position: relative;

    width: 100%;
    height: 100%;

    .newsImg {
      width: 100%;
      height: 50%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .newsTitle {
      @include text($textFont, 700, 20px, 28px, $textColor);

      overflow: hidden;
      display: -webkit-box;

      height: 50px;
      margin: 10px 0 15px;
      padding: 0 5%;

      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .newsText {
      @include text($textFont, 400, 14px, 24px, $textColor);

      overflow: hidden;
      display: -webkit-box;

      height: 70px;
      padding: 0 5%;

      text-align: start;

      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      -webkit-tap-highlight-color: transparent;
    }

    .newsData {
      position: absolute;
      bottom: 20px;
      left: 5%;
    }
  }
}

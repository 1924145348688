@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.slide {
  max-width: 800px;
  img {
    width: 100%;
  }
}

.container {
  padding: 0 30px 30px;
  &__header {
    margin-top: 0;

    text-align: center;
    &_title {
      margin-bottom: 40px;
    }
    &_text {

      @include text($textFont, 400, 16px, 17px, rgba(0, 0, 0, 0.45));

      text-align: left;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    margin-top: 40px;

    &_back {

      @include text($textFont, 400, 18px, 22px, black);

      cursor: pointer;

      width: 340px;
      height: 70px;

      background: $backgroundSecondColorButton;
      border: none;
      &:hover {
        background: $textLinkColorHover;
      }
    }

    &_reserve {
      width: 340px;
      height: 70px;

      button {

        @include text($textFont, 400, 18px, 22px, $textButtonColor);

        cursor: pointer;

        width: 100%;
        height: 100%;

        background: $backgroundColorButton;
        border: none;
        &:hover {
          background: $backgroundColorButtonHover;
        }
      }
    }
  }
}

.homeRoomsContainer {

  @include adpt($tab-r) {
    width: 75%;
  }

  width: 70%;
  margin: 0 auto 30px;
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.headerContainer {
  @include adpt($bmob-r) {
    height: 60px;
  }

  position: fixed;
  z-index: 100;
  top: 0;

  width: 100%;
  height: 84px;

  background-color: $backgroundColor;

  .header {
    @include flexCenterJustify(space-between);
    @include adpt($tab-r) {
      width: 90%;
    }
    display: flex;
    align-items: center;
    width: 70%;
    height: 100%;
    margin: 0 auto;
    color: #fff;
    .block {
      display: none;
    }
    .logo {
      @include adpt($stab-r) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include adpt($bmob-r) {
        height: 84px;
      }

      width: 120px;
      margin-right: 78px;
    }
  }
}

.headerContainer_active {
  position: absolute;
  z-index: 100;
  top: 0;

  width: 100%;
  height: 138px;

  background-color: $backgroundColor;
  @include adpt($stab-r) {
    height: 84px;
    position: fixed;
  }
  @include adpt($bmob-r) {
    height: 60px;
  }
  .header {
    @include flexCenterJustify(space-between);

    @include adpt($stab-r) {
      align-items: center;
    }
    @include adpt($tab-r) {
      width: 90%;
    }

    display: flex;
    align-items: flex-end;

    width: 70%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    color: #fff;
    .block {
      position: absolute;
      top: 0;
      right: 0;
      width: 55%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      @include adpt($big-r) {
        width: 60%;
      }
      @include adpt($desm-r) {
        width: 67%;
      }
      @include adpt($stab-r) {
        display: none;
      }
      .address {
        @include text($textFont, 400, 14.4px, 22px, $textLinkColorHover);
        width: 50%;
        align-items: center;
        display: flex;
        @include adpt($desm-r) {
          font-size: 14px;
        }

        &_text {
          margin-left: 15px;
        }

        div {
          position: relative;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      form {
        @include adpt($desm-r) {
          width: 180px;
          margin: 0 auto;
        }
        @include adpt($mtab-r) {
          width: 150px;
        }

        display: block;

        width: 225px;
        height: 43px;
        button {
          @include text($textFont, 400, 12px, 22px, $textColor);
          cursor: pointer;

          width: 100%;
          height: 100%;

          background-color: $textLinkColorHover;
          border: none;

          &:hover {
            background: $backgroundSecondColorButton;
          }
        }
      }
    }
    .logo {
      @include adpt($stab-r) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include adpt($stab-r) {
        width: 80px;
      }
      max-width: 198px;
      margin: 5px 0;
      width: 100%;
      max-height: 140px;
    }
  }
}

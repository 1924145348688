@import '../../../../styles/mixin';

@import '../../../../styles/variables';

.whatIsNear {
  width: 100%;
  .whatIsNearContainer {
    width: 70%;
    margin: 0 auto;
  }
}

@import '../../../../../styles/mixin';

@import '../../../../../styles/variables';

.roomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  text-align: center;
  .room {
    width: 100%;
    height: 100%;
    .roomImg {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .roomTitle {
      @include text($textFont, 700, 20.568px, 28px, $textColor);

      margin: 10px 0 15px;
      padding: 0 20px;
    }
    .roomText {
      @include text($textFont, 400, 14.4px, 24px, rgba(0, 0, 0, 0.87));

      padding: 0 20px;

      -webkit-tap-highlight-color: transparent;
    }
  }
  .button {
    @include text($textFont, 500, 14.4px, 21.6px, $textSecondColor);

    @include adpt($stab-r) {
      width: 200px;
    }

    cursor: pointer;

    width: 225px;
    height: 55px;
    margin-bottom: 40px;

    background-color: $backgroundColorButton;
    border: none;
    &:hover {
      background: $backgroundColorButtonHover;
    }
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.FAQItem {
  position: relative;

  width: 100%;
  margin-bottom: 30px;

  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //padding: 20px;
  //z-index: 4;

  .dropButton {

    @include text($textFont, 400, 22px, 32px, #fff);

    position: relative;
    z-index: 4;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    padding: 20px;

    background-color: #375b6b;
    border: none;

    transition: transform 0.5s;
  }

  .arrowRight {

    @include text($textFont, 400, 22px, 32px, #fff);
  }

  .arrowRight::after {
    content: url('../../../img/arrows/FFF/ArrowR.svg');

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(0deg);

    align-content: center;

    width: 20px;
    height: 20px;

    transition: transform 0.5s;
  }

  .jsRotateArrow {
  }

  .jsRotateArrow::after {
    content: url('../../../img/arrows/FFF/ArrowR.svg');

    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%) rotate(90deg);

    width: 20px;
    height: 20px;

    transition: transform 0.5s;
  }

  .dropItem {

    @include text($textFont, 400, 18px, 24px, #375b6b);

    position: relative;

    margin-bottom: 8px;
    padding: 20px;

    background-color: white;

    transition: display, 1s;
  }
}

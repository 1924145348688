@import '../../../../../styles/mixin';

@import '../../../../../styles/variables';

.hostelContainer {
  @include adpt($tab-r) {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 49%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }

  .hostelTitle {
    @include text($textFont, 400, 27.36px, 30.096px, $textColor);
    text-decoration: none;
    margin: 15px 0 0;
  }
  .hostelText {
    @include text($textFont, 400, 14.4px, 24px, rgba(0, 0, 0, 0.87));

    margin-bottom: 15px;
  }
}

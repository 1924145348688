/*Museo*/

@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 100;
  src: local('fonts/MuseoSansCyrl-100');
  src: url('./fonts/MuseoSansCyrl-100.ttf') format('truetype');
  src: url('./fonts/MuseoSansCyrl-100.eot');
  src: url('./fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype');
  src: url('./fonts/MuseoSansCyrl-100.woff') format('woff');
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 300;
  src: local('fonts/MuseoSansCyrl-300');
  src: url('./fonts/MuseoSansCyrl-300.ttf') format('truetype');
  src: url('./fonts/MuseoSansCyrl-300.eot');
  src: url('./fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype');
  src: url('./fonts/MuseoSansCyrl-300.woff') format('woff');
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 500;
  src: local('fonts/MuseoSansCyrl-500');
  src: url('./fonts/MuseoSansCyrl-500.ttf') format('truetype');
  src: url('./fonts/MuseoSansCyrl-500.eot');
  src: url('./fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype');
  src: url('./fonts/MuseoSansCyrl-500.woff') format('woff');
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 700;
  src: local('fonts/MuseoSansCyrl-700');
  src: url('./fonts/MuseoSansCyrl-700.ttf') format('truetype');
  src: url('./fonts/MuseoSansCyrl-700.eot');
  src: url('./fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype');
  src: url('./fonts/MuseoSansCyrl-700.woff') format('woff');
}

@font-face {
  font-family: 'Museo Sans Cyrl';
  font-weight: 900;
  src: local('fonts/MuseoSansCyrl-900');
  src: url('./fonts/MuseoSansCyrl-900.ttf') format('truetype');
  src: url('./fonts/MuseoSansCyrl-900.eot');
  src: url('./fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype');
  src: url('./fonts/MuseoSansCyrl-900.woff') format('woff');
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Museo Sans Cyrl', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import '../../../../../styles/mixin';

@import '../../../../../styles/variables';

.nearContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  text-align: center;
  .near {
    width: 100%;
    height: 100%;
    .nearImg {
      width: 100%;
      height: 60%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nearTitle {
      @include text($textFont, 700, 20.568px, 28px, $textColor);

      @include adpt($bmob-r) {
        padding: 0 5px;
      }

      margin: 10px 0 15px;
      padding: 20px;
    }
    .nearText {
      @include text($textFont, 700, 20.568px, 28px, rgba(0, 0, 0, 0.87));

      padding: 0 20px;

      -webkit-tap-highlight-color: transparent;
    }
  }
}

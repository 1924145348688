@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.servicesBlock_container {
  display: flex;
  flex-wrap: wrap;

  width: 70%;
  margin: 0 auto;
  @include adpt($tab-r) {
    width: 90%;
  }
  .servicesBlock {
    width: 220px;
    margin: 0 auto 20px;
    padding: 0 5px;
    height: 300px;
    .slideContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
    .slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      .slideImg {
        display: flex;
        justify-content: center;

        width: 100px;
        height: 100px;
      }
      .slideText {
        @include text($textFont, 600, 18px, 24px, #263d4b);

        margin-top: 20px;
        padding: 0 30px;

        text-align: center;
      }
    }
  }
}

@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.container {
  @include adpt($stab-r) {
    width: 30%;
    justify-content: space-between;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 84px;
  text-decoration: none;
  .burger {
    @include adpt($stab-r) {
      display: flex;
    }

    display: none;

    a:hover {
      color: $textLinkColorHover;
      text-decoration: none;
    }

    .buttonMenu {
      @include button(none, none, 0);

      cursor: pointer;
    }
  }
  .menu {
    @include flexCenterJustify(space-evenly);
    @include adpt($stab-r) {
      display: none;
    }

    width: 100%;
    padding-left: 80px;

    a {
      @include text($textFont, 400, 14.4px, 24px, $textLinkColor);

      text-decoration: none;
    }

    a:hover {
      color: $textLinkColorHover;
      text-decoration: none;
    }

    .active {
      color: $textLinkColorHover;
    }
  }
}
.burgerLinks {
  li {
    align-items: center;
    justify-content: center;

    width: 156px;
  }
  .burgerLink {
    a {
      @include text($textFont, 18px, 700, 21px, $textColor);

      text-decoration: none;
      &:hover {
        color: $textLinkColorHover;
        text-decoration: none;
      }
    }

    .active {
      color: $textLinkColorHover;
    }
  }
}

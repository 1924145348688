@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.services {
  width: 100%;

  background-color: #f5f5f5;
  .servicesContainer {
    width: 70%;
    margin: 0 auto 80px;
  }
  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 95%;
    height: 200px;
    margin: 0 auto;

    .slideImg {
      width: 100px;
      height: 100px;

      background-position: center;
      background-size: cover;
      img {
        margin: 0 auto;
      }
    }
    .slideText {

      @include text($textFont, 400, 18px, 24px, $textColor);

      width: 120px;
      margin-top: 20px;

      text-align: center;
    }
  }
}

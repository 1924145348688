@import 'src/styles/mixin.scss';

@import 'src/styles/variables.scss';

.FAQBlock {

  @include adpt($tab-r) {
    width: 90%;
  }

  width: 70%;
}
